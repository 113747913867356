export const ROUTES = {
  HOMEPAGE: '/',
  LOGIN: '/login',

  USERS_LIST: '/users',
  USERS_DETAIL: '/users-detail',
  CREATE_USERS: '/users-create',
  EDIT_USERS: '/users-edit',

  VIDEOS_LIST: '/videos',
  VIDEO_DETAIL: '/videos-detail',
  CREATE_VIDEOS: '/videos-create',
  EDIT_VIDEOS: '/videos-edit',
  BOOKMARK_LIST: '/videos-bookmark-list',
  BOOKMARK_CREATE: '/videos-bookmark-create',
  TUTORIAL_LIST: '/videos-tutorial-list',
  TUTORIAL_CREATE: '/videos-tutorial-create',
  HOT_SPOT_RANKING_LIST: '/videos-hotspot-ranking-list',
  HOT_SPOT_RANKING_EDIT: '/videos-hotspot-ranking-edit',
  
  ORDER_LIST: '/order',
  ORDER_AIRTEL: '/order-airtel',

  COUPONS_MANAGEMENT: "/benefits-coupons-management",
  PROMOTION_MANAGEMENT: "/benefits-promotion-management",

  PRODUCT_HOTEL_MANAGEMENT: "/product-hotel-management",
  PRODUCT_HOTEL_MANAGEMENT_DETAIL: "/product-hotel-management-detail",
  PRODUCT_HOTEL_CURATION: "/product-hotel-curation",
  PRODUCT_HOTEL_CURATION_DETAIL: "/product-hotel-curation-detail",
  PRODUCT_HOTEL_RANKING_LIST: "/product-hotel-ranking-list",
  PRODUCT_HOTEL_RANKING_EDIT: "/product-hotel-ranking-edit",
  PRODUCT_RAKUTEN: "/product-rakuten",
  PRODUCT_AIRTEL: "/product-airtel",
  PRODUCT_AIRTEL_DETAIL: "/product-airtel-detail",

  CONTENTS_CURATION: '/contents-curation',
  CREATE_CONTENTS_CURATION: '/create-contents-curation',

  NOTIFICATIONS_LIST: '/notifications',
  CREATE_NOTIFICATIONS: '/notifications-create',
  EDIT_NOTIFICATIONS: '/notifications-edit',
  POST_NOTIFICATIONS_LIST: '/post-notifications',
  CREATE_POST_NOTIFICATIONS: '/post-notifications-create',
  EDIT_POST_NOTIFICATIONS: '/post-notifications-edit',

  ALBUMS_LIST: '/projects',
  CREATE_ALBUMS: '/projects-create',
  EDIT_ALBUMS: '/projects-edit',
  ALBUM_DETAIL: '/projects-detail',

  REPORTS_LIST: '/reports',
  EDIT_REPORTS: '/reports-edit',

  PRIVACY: '/privacy',
  TERMS_POLICY: '/terms_policy',

  SONGS_LIST: '/songs',
  EDIT_SONG: '/songs-edit',
  CREATE_SONG: '/songs-create',
  SONG_DETAIL: '/songs-detail',

  GENRES: '/genres',
  EDIT_GENRES: '/genres-edit',
  CREATE_GENRES: '/genres-create',

  STICKERS: '/stickers',
  EDIT_STICKERS: '/stickers-edit',
  CREATE_STICKERS: '/stickers-create',

  STICKER_GROUPS: '/sticker-groups',
  EDIT_STICKER_GROUPS: '/sticker-groups-edit',
  CREATE_STICKER_GROUPS: '/sticker-groups-create',

  TRANSITIONS: '/transitions',
  EDIT_TRANSITIONS: '/transitions-edit',
  CREATE_TRANSITIONS: '/transitions-create',

  FILTERS: '/filters',
  EDIT_FILTERS: '/filters-edit',
  CREATE_FILTERS: '/filters-create',

  COUNTRIES: '/countries',
  EDIT_COUNTRY: '/countries-edit',
  CREATE_COUNTRY: '/countries-create',

  CITIES: '/cities',
  EDIT_CITY: '/cities-edit',
  CREATE_CITY: '/cities-create',

  TOWNS: '/towns',
  EDIT_TOWN: '/towns-edit',
  CREATE_TOWN: '/towns-create',

  HASHTAGS: '/hashtags',
  EDIT_HASHTAG: '/hashtags-edit',
  CREATE_HASHTAG: '/hashtags-create',

  TEXTS: '/texts',
  EDIT_TEXT: '/texts-edit',
  CREATE_TEXT: '/texts-create',

  FRAMES: '/frames',
  EDIT_FRAME: '/frames-edit',
  CREATE_FRAME: '/frames-create',

  CATEGORIES: '/categories',
  EDIT_CATEGORY: '/categories-edit',
  CREATE_CATEGORY: '/categories-create',

  EVENTS: '/events',
  EVENTS_POPUP: '/events-popup',
  EVENTS_SHOP_BANNER: '/events-shop-banner',
  EDIT_EVENT: '/events-edit',
  CREATE_EVENT: '/events-create',

  PUSH_NOTIFICATIONS: '/push-notifications',
  CREATE_PUSH_NOTIFICATIONS: '/push-notification-edit',
  EDIT_PUSH_NOTIFICATIONS: 'push-notification-create',
};

export const PUBLIC_ROUTES = [ROUTES.LOGIN, ROUTES.TERMS_POLICY, ROUTES.PRIVACY];
