import { ALBUM_STATUS, ERROR_CODE, REPORT_TYPE, TAG_NAMES, HOTEL_PRODUCT_TYPE } from 'utils/constants';
import { DEFAULT_ERROR_MESSAGE, ALBUM_TYPES, FILE_TYPES, INPUT_FILE_TYPES } from 'utils/constants';
import { notification } from 'antd';
import { colors } from 'components/Styles/Colors';
import dayjs from 'dayjs';
import axios from 'axios';

export function defineCancelApiObject(apiObject) {
  const cancelApiObject = {};

  Object.getOwnPropertyNames(apiObject).forEach((apiPropertyName) => {
    const cancellationControllerObject = {
      controller: undefined,
    };

    cancelApiObject[apiPropertyName] = {
      handleRequestCancellation: () => {
        if (cancellationControllerObject.controller) {
          cancellationControllerObject.controller.cancel();
        }
        
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        cancellationControllerObject.controller = source;

        return cancellationControllerObject.controller;
      },
    };
  });

  return cancelApiObject;
}

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1;
  }
  return new File([u8arr], filename, { type: mime });
};

export function toYYYYMMDD(date) {
  return dayjs(date).format('YYYY.MM.DD');
}
export function toYYYYMMDDddd(date) {
  return dayjs(date).format('YYYY.MM.DD (ddd)');
}
export function toHHMMSS(date) {
  return dayjs(date).format("HH:mm:ss");
}

function _convertDMSToDD (degrees, minutes, seconds, direction) {
  var dd = Number(degrees) + Number(minutes)/60 + Number(seconds)/(60*60);

  if (direction == "S" || direction == "W") {
      dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
}

export const dmsToDd = (dms) => {
  var parts = dms.split(/[^\d\w\.]+/);
  var lat = _convertDMSToDD(parts[0], parts[2], parts[3], parts[4]);
  var lng = _convertDMSToDD(parts[5], parts[7], parts[8], parts[9]);

  return {
      lat,
      lng
  }
};

function _toDegreesMinutesAndSeconds(coordinate) {
  var absolute = Math.abs(Number(coordinate))
  var degrees = Math.floor(Number(absolute))
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated)
  var seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2)

  return `${degrees} deg ${minutes}' ${seconds}\"`;
}

export const ddToDms = (dd) => {
  const { lat, lng } = dd
  if (lat == 0 || lng == 0) return '';
  var latitude = _toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? "N" : "S";

  var longitude = _toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? "E" : "W";

  return latitude +
      " " +
      latitudeCardinal +
      ", " +
      longitude +
      " " +
      longitudeCardinal;
};

const handleMessage = (prefix, message) => `${prefix}: ${message}`;
export const toMMSSSS = (myNum) => {
  var hours = Math.floor(myNum / 3600);
  var minutes = Math.floor((myNum - hours * 3600) / 60);
  var seconds = myNum - hours * 3600 - minutes * 60;
  const arr = myNum.toString().split('.');
  var milliSeconds = myNum - hours * 3600 - minutes * 60;
  if (arr[1]) {
    seconds = arr[0];

    milliSeconds = arr[1].substr(0, 2);
  }

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (milliSeconds === 0) {
    milliSeconds = '00';
  } else if (milliSeconds < 10) {
    milliSeconds = '0' + milliSeconds;
  }
  const returnValue = minutes + ':' + seconds + ':' + milliSeconds;

  return returnValue;
};
export const handleError = (err, prefixMessage) => {
  try {
    const errRes = err?.response?.data ?? null;
    let message = null;
  
    if (errRes) {
      const errorCode = errRes.data ? String(errRes.data).toLowerCase() : 'default';
      message = ERROR_CODE[errRes.statusCode][errorCode];
    }
    
    const errors = err?.response?.data?.data?.errors;
    if (!message) {
      message = errors
        ? errors[Object.keys(errors)[0]][0]
        : err.response?.data?.message || DEFAULT_ERROR_MESSAGE;
    }
    notification.error({
      message: handleMessage(prefixMessage, message),
    });
  }
  catch (e) {
    notification.error({
      message: handleMessage('Internal Error', '내부 에러 발생')
    });
  }
};

export const generateStatusColor = (status) => {
  let color;

  switch (status) {
    case ALBUM_STATUS.DELETED:
      color = 'red';
      break;
    case ALBUM_STATUS.NEW:
      color = 'green';
      break;
    case ALBUM_STATUS.EXPIRED:
      color = 'gold';
      break;
    case TAG_NAMES.PRIVATE:
      color = 'gold';
      break;
    case TAG_NAMES.PUBLIC:
      color = 'green';
      break;
    case TAG_NAMES.BLOCKED:
      color = 'red';
      break;
    case TAG_NAMES.OPEN:
      color = 'geekblue';
      break;
    case TAG_NAMES.PICK:
    case TAG_NAMES.NOT_PICK:
      color = 'pink';
      break;
    case TAG_NAMES.JA:
    case TAG_NAMES.KO:
    case TAG_NAMES.EN:
    case TAG_NAMES.ETCLANG:
      color = 'cyan';
      break;
    case TAG_NAMES.SET_ALL:
    case TAG_NAMES.RESET:
      color = colors().primaryColor;
      break;
    case REPORT_TYPE.FEEDBACK:
      color = 'geekblue';
      break;
    case REPORT_TYPE.VIDEO:
      color = 'green';
      break;
    case REPORT_TYPE.USER:
      color = 'gold';
      break;
    default:
      color = 'default';
      break;
  }

  return color;
};
export const generateKorean = (status) => {
  let korean = '';
  switch (status) {
    case 'PRIVATE':
      korean = '비공개';
      break;
    case 'PUBLIC':
      korean = 'green';
      break;
    case TAG_NAMES.BLOCKED:
      korean = 'red';
      break;
    case TAG_NAMES.OPEN:
      korean = 'geekblue';
      break;
    case TAG_NAMES.PICK:
      korean = 'pink';
      break;
    case TAG_NAMES.NOT_PICK:
      korean = colors().tertiaryLight;
      break;
    case 'ko':
      korean = '한국어';
      break;
    case 'en':
      korean = '영어';
      break;
    case 'ja':
      korean = '일본어';
      break;
    default:
      korean = '';
      break;
  }
};

export const secondsToHms = (time, digitOnly = false) => {
  let d = Number(time);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const formattedM = digitOnly ? m : `${m}m`;
  const formattedS = digitOnly ? s : `${s}s`;

  if (h !== 0) {
    const formattedH = digitOnly ? h : `${h}h`;

    return `${formattedH}:${formattedM}:${formattedS}`;
  }

  return `${formattedM}:${formattedS}`;
};

export const textLabelling = (text) => {
  switch (text) {
    case ALBUM_TYPES.MY_ALBUM:
      return 'MY PROJECTS';
    case ALBUM_TYPES.SHARED_ALBUM:
      return 'SHARED PROJECTS';
    default:
      return text;
  }
};

export const validFileType = (file, fileType) => {
  let fileTypes;

  switch (fileType) {
    case INPUT_FILE_TYPES.AUDIO:
      fileTypes = FILE_TYPES.AUDIO;
      break;
    case INPUT_FILE_TYPES.IMAGE:
      fileTypes = FILE_TYPES.IMAGE;
      break;
    default:
      fileTypes = [];
  }

  return fileTypes.includes(file.type);
};

export function isArray(myArray) {
  return myArray.constructor.toString().indexOf('Array') > -1;
}

export function isNotEmptyArray(myArray) {
  return isArray(myArray) && myArray.length > 0;
}

export const joinByKey = (array = [], key = '', separator = ', ') =>
  array.map((item) => item[key]).join(separator);

export const findNextPrevIDs = (id, list = []) => {
  const currentIndex = list.findIndex((i) => i.id.toString() === id);

  const isFirstElem = currentIndex === 0;
  const isLastElem = currentIndex === list.length - 1;

  return {
    prev: isFirstElem ? null : list[currentIndex - 1],
    next: isLastElem ? null : list[currentIndex + 1],
  };
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const isValidUrl = (urlString) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const reverseGeocoding = async (latlng, language) => {
  const geo = new window.google.maps.Geocoder();
  const res = await geo.geocode({ location: latlng, language: language });

  return res.results[0];
};

export const reverseGeocodingAsync = (id, latlng, language) => {
  return new Promise((res, rej) => {
    const geo = new window.google.maps.Geocoder();
    geo.geocode({ location: latlng, language: language }, function (results, status) {
      res({ status, id, language, placeID: results[0].place_id });
    });
  });
};

export const getDetails = (placeID, language, map) => {
  // return new Promise((res, rej) => {
  //   const service = new google.maps.places.PlacesService(map);
  //   service.getDetails(
  //     {
  //       placeId: placeID,
  //       language: language,
  //     },
  //     function callback(place, status) {
  //       res({ place, status });
  //     },
  //   );
  // });
};

export const convertUTCToKST = utcDate => {
  const date = new Date(utcDate.toISOString())
  date.setHours(date.getHours() + 9)

  return date
}

export const getProductTypeColor = (productType) => {
  let otaColor = '';
  if (productType === HOTEL_PRODUCT_TYPE.RAKUTEN) {
    otaColor = '#54822b';
  } else if (productType === HOTEL_PRODUCT_TYPE.HOTELNJOY) {
    otaColor = '#f69e05';
  } else if (productType === HOTEL_PRODUCT_TYPE.ONDA) {
    otaColor = '#5596f7';
  } else if (productType === HOTEL_PRODUCT_TYPE.YANOLJA) {
    otaColor = '#FF3478';
  } else otaColor = '#fff';
  return otaColor;
};